<template>
  <main>
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <div>
      <router-view @listLoad="load"/>
    </div>
    <b-container class="bg-light" style="padding:20px 100px 10px 100px" fluid>
      <b-row>
        <b-col v-if="pending">
        </b-col>
        <b-col v-else>
          <div class="text-left d-flex justify-content-start mb-5">
            <span class="title mr-5">Usage metering Report</span>
            <div class="d-flex justify-content-start align-items-center">
              <v-date-picker ref="startDatePicker" v-model="startDate" :masks="{input:'YYYY/MM/DD'}" :disabled-dates="disableDate" >
                <template #default="{ inputValue, inputEvents }">
                  <b-input-group class="input">
                    <b-form-input style="width:150px" :value="inputValue" v-on="inputEvents"  />
                    <b-input-group-append is-text >
                      <b-icon-calendar4 font-scale="0.9"></b-icon-calendar4>
                    </b-input-group-append>
                  </b-input-group>
                </template>
              </v-date-picker>
              <span class="mx-2">~</span>
              <v-date-picker v-model="endDate" :masks="{input:'YYYY/MM/DD'}" :disabled-dates="disableDate" >
                <template #default="{ inputValue, inputEvents }">
                  <b-input-group class="input">
                    <b-form-input style="width:150px" :value="inputValue" v-on="inputEvents"  />
                    <b-input-group-append is-text variant="success">
                      <b-icon-calendar4 font-scale="0.9"></b-icon-calendar4>
                    </b-input-group-append>
                  </b-input-group>
                </template>
              </v-date-picker>
            </div>
          </div>
          <b-card class="w-100">
            <b-card-title class="text-left">
              <span class="chart-title">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.85" fill-rule="evenodd" clip-rule="evenodd" d="M0 15V22C0 26.4183 3.58172 30 8 30H15H22C26.4183 30 30 26.4183 30 22V15V8C30 3.58172 26.4183 0 22 0H15H8C3.58172 0 0 3.58172 0 8V15Z" fill="#8280FF"/>
                  <path d="M8.77778 20.2222H22.1111C22.602 20.2222 23 20.6202 23 21.1111C23 21.602 22.602 22 22.1111 22H7.88889C7.39797 22 7 21.602 7 21.1111V6.88889C7 6.39797 7.39797 6 7.88889 6C8.37981 6 8.77778 6.39797 8.77778 6.88889V20.2222Z" fill="white"/>
                  <path opacity="0.5" d="M12.0929 16.3857C11.7572 16.7439 11.1946 16.762 10.8365 16.4263C10.4783 16.0905 10.4602 15.528 10.796 15.1698L14.1293 11.6143C14.454 11.2679 14.9936 11.2379 15.3548 11.5461L17.9856 13.7911L21.4134 9.44921C21.7176 9.0639 22.2766 8.99814 22.6619 9.30234C23.0472 9.60653 23.113 10.1655 22.8088 10.5508L18.8088 15.6175C18.4963 16.0132 17.9177 16.0701 17.5341 15.7428L14.8461 13.449L12.0929 16.3857Z" fill="white"/>
                </svg>
                Total Cost
              </span>
              <span class="chart-subTitle">
                데이터 사용량(문항수 X 샘플수)
              </span>
              <div style="font-size:28px; font-weight:500" class="mt-4 ml-5 mb-3">
                <span class="mr-3">{{totalValue.toLocaleString() + "원"}}</span>
                <span class="mr-2 chart-subTitle">문항수 : {{questions.toLocaleString()}}개</span>
                <span class="chart-subTitle">샘플수 {{answers.toLocaleString()}}개</span>
              </div>
            </b-card-title>
            <b-card-body>
              <chart
                  id="chart"
                  v-if="chartData"
                  :chart-data="chartData"
                  :options="chartOptions"
                  ref="chart"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import chart from './chart'
import dayjs from 'dayjs'

export default {
  name: "index",
  components: { chart },
  data:()=>({
    disableDate:[{ start:null, end: new Date(2024, 7, 0)},{ start:dayjs(new Date()).add(1,'day').toDate(),end: null}],
    pending: false,
    startDate:dayjs(new Date()).subtract(6,'day').format("YYYY/MM/DD"),
    endDate:dayjs(new Date()).format("YYYY/MM/DD"),
    totalValue:0,
    answers:0,
    questions:0,
    calendarFlag:false,
    chartData: {
      labels: [],
      datasets: [{
        barPercentage: 0.5,
        data: [],
        backgroundColor: '#739DFF',
        borderColor: '#739DFF',
        borderWidth: 1
      }]
    },
    chartOptions: {
      maintainAspectRatio:false,
      responsive: true,
      legend: {
        display: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize:200000,
            color:'#86909C',
            callback: function(value, index, ticks) {
              return value.toLocaleString();
            }
          }
        }],
        xAxes:[{
          gridLines: {
            display: false // y축 눈금선 숨기기
          },
        }]
      },
      plugins: {
        datalabels: {
          // 값 표시 설정
          anchor: 'end', // 막대 끝에 표시
          align: 'top', // 막대 위쪽에 정렬
          color: 'black', // 글자 색상
          offset:-5,
          font: {
            weight: 'bold' // 글자 굵기
          },
          formatter: (value) => value.toLocaleString() // 값 형식 지정 (예: 1000 -> 1,000 원)
        }
      }
    }
  }),
  watch: {
    endDate(newEndDate) {
      const now = dayjs()
      const newEndDateDayjs = dayjs(newEndDate);
      const startDateDayjs = dayjs(this.startDate);
      if(newEndDateDayjs.isAfter(now)){
        this.endDate = now.subtract(1,'day')
      }
      if (newEndDate && this.startDate) {
        if (startDateDayjs.isAfter(newEndDateDayjs)) {
          const adjustedStartDate = newEndDateDayjs;
          this.startDate = adjustedStartDate.toDate(); // Date 객체로 변환하여 startDate에 할당
        }

        this.drawingChart()
      }
    },
    startDate(newStartDate){
      if (newStartDate && this.endDate) {
        const newStartDateDayjs = dayjs(newStartDate);
        const endDateDayjs = dayjs(this.endDate);
        if (newStartDateDayjs.isAfter(endDateDayjs) ) {
          const adjustedEndDate = newStartDateDayjs;
          this.endDate = adjustedEndDate.toDate(); // Date 객체로 변환하여 startDate에 할당
        }

        this.drawingChart()
      }
    },
  },
  created() {
    this.drawingChart()
  },
  methods: {
    async drawingChart(){
      try {
        this.chartData.labels=[]
        this.chartData.datasets[0].data=[]
        const params = {
          startDate:dayjs(this.startDate).format("YYYY-MM-DD"),
          endDate:dayjs(this.endDate).format("YYYY-MM-DD")
        }

        const {data} = await this.axios({
          url: `admin/payment-history/dashboard`,
          params
        })
        const values=[]
        const labels=[]
        let answers=0
        let questions=0
        let total = 0
        let max = 0;
        data.list.forEach(item=>{
          labels.push(item.date);
          values.push(item.amount ===0 ? "" : item.amount);
          total+=Number(item.amount);
          answers+=Number(item.answers);
          questions+=Number(item.questions);
          max = max < item.amount ? item.amount : max
        })
        this.totalValue=total;
        this.answers=answers;
        this.questions=questions;
        this.chartData.labels=labels;
        this.chartData.datasets[0].data=values;
        //this.chartOptions.scales.yAxes[0].ticks.max = max + 500000
        this.$refs.chart.reRendering()
      }catch (e){
        console.log(e)
      }
    },
    async load () {
      try {

      } catch (e){
        console.log(e)
      }
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}
.chart-title{
  font-size: 26px;
  font-weight: 700;
  line-height: 20px;
}
.chart-subTitle{
  font-weight: 400;
  font-size: 16px;
  color: #828282;
}
#chart{
  aspect-ratio:30/10;
}
.input ::v-deep .input-group-text{
  background:#FFFFFF
}
</style>
