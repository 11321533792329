<script>
import { Bar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';
export default {
  name: "chart",
  extends: Bar,
  props: {
    chartData: Object,
    options: Object
  },
  mounted () {
    this.addPlugin(ChartDataLabels)
    this.renderChart(this.chartData, this.options)
  },
  methods:{
    reRendering(){
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>

<style scoped>

</style>
